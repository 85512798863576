function Link({ href, name, blank=false }) {
	return (
		<a 
			target={blank ? '_blank' : '_self'}
			href={href}
			className="
			hover:cursor-pointer
			hover:border-transparent
			text-Regular(14_16) text-Accent/Blue border border-Accent/Blue border-l-0 border-r-0 border-t-0
		">{name}</a>
	);
}

export default Link;