import { useEffect, useState, forwardRef } from "react";
import Container from "../container/container";
import useDashbord from "../../hooks/dashbord.hook";
import DatePicker, { registerLocale } from "react-datepicker";
import { toISOStringWithTimezone } from '../../helpers/common'
import Loader from '../loader/loader'



function StatusLegend({statuses}) {


	statuses=statuses.filter(x => x.description)

	if(!statuses.length) return null

	return (
		< Container >
			<div className="text-Regular(16_18) font-semibold mb-[1.2rem]">Подсказка по статусам</div>
			<div className="grid grid-cols-3 gap-[1px]">
				{statuses?.map(({ description, title, color }) => {
					if (!description) return null
					return (
						<div
							style={{
								backgroundColor:color
							}} 
							className="p-[0.8rem]">
							<span className="font-bold text-Regular(14_16)">
								{title}:  
							</span>
							<span className="text-Regular(14_16)">
								{' ' +description}
							</span>
						</div>
					)
				})}
			</div>

		</ Container>
	);
}

export default StatusLegend;