
import dashbordStore from '../../store/dashbord'
import usePosition from '../../hooks/position.hook';
import DropdownHOC from '../dropdownHOC/dropdownHOC';


function ActionDropdown({ onEvent, positions = false, setIsMobileModalOpen = null, statuses = null }) {


	const { setStagesToUrgent, setPositionsToUrgent, setPositionsToCanceled } = usePosition();



	const finishAction = () => {
		dashbordStore.clearSelected();
		onEvent && onEvent();
	}

	const setPositionsToUrgentHandler = async () => {
		console.log(dashbordStore.selectedPositionsStages)
		// return;
		await setPositionsToUrgent({
			ids: dashbordStore.selectedPositionsStages.map(x => x.position_id),
			isUrgent: true
		});
		finishAction()
	}

	const setStageToUrgentHandler = async () => {
		await setStagesToUrgent(dashbordStore.getSelected().map(x => x.id));
		finishAction()
	}

	const setPositionsToCanceledHandler = async () => {
		await setPositionsToCanceled(dashbordStore.selectedPositionsStages.map(x => x.position_id));
		finishAction()
	}

	const onSetStatusesModal = () => {
		setIsMobileModalOpen && setIsMobileModalOpen(true)
	}

	const itemsArr = statuses ? [
		{
			onEvent: positions ? setPositionsToUrgentHandler :  setStageToUrgentHandler,
			title: "Сделать позиции срочными"
		},
		{
			onEvent: setPositionsToCanceledHandler,
			title: "Отменить позиции"
		},
		{
			onEvent: onSetStatusesModal,
			title: "Сменить статус"
		},
	] : [
		{
			onEvent: positions ? setPositionsToUrgentHandler :  setStageToUrgentHandler,
			title: "Сделать позиции срочными"
		},
		{
			onEvent: setPositionsToCanceledHandler,
			title: "Отменить позиции"
		}
	]

	return (
		<div className="max-w-[24rem]">

			<DropdownHOC
				items={itemsArr}
				title="Действия с выбранными"
			></DropdownHOC>
		</div>

	);
}

export default ActionDropdown;