import { observer } from "mobx-react-lite"
import stageStore from "../../store/stage"
import usersStore from "../../store/users"
import useStages from "../../hooks/stages.hook";
import { useEffect } from "react";
import dashbordStore from "../../store/dashbord";

function Stages() {

	const { stages = [] } = useStages();
	const { id } = stageStore.currentStage || {};
	const { role } = usersStore.currentUser;


	useEffect(() => {
		if (role && stages.length) {
			const stage = stages.find(x => x?.id === role[0].stage?.id);
			stageStore.setCurrentStage(stage)
			stageStore.setStages(stages)
		}
	}, [role, stages])


	const onStageChange = (stage) => {
		stageStore.setCurrentStage(stage)
		dashbordStore.clearSelected()
	}

	if (!stages.length) return null

	return (
		<div className="flex gap-[0.8rem] pb-[1.8rem] overflow-auto">
			<div
				className="flex flex-col items-center"
				onClick={() => onStageChange(null)}
			>
				<i className={`icon-all-stages
				${!id ? 'text-Accent/Yellow' : 'text-transparent'}
				text-Regular(24_30) mb-[0.4rem]`}> </i>
				<div
					className={`
                        ${!id ? 'bg-Accent/Yellow pointer-events-none' : 'bg-Dominant/Dop'}
                        hover:cursor-pointer hover:bg-white hover:border-Accent/Yellow p-[0.5rem] text-center flex-auto w-[12.6rem] h-[4.4rem]  border border-Content/Border flex items-center justify-center
                    `}>
					<div className="text-Regular(14_16)">Все заказы</div>
				</div>

			</div>

			{stages.map(x => (
				<div
					onClick={() => onStageChange(x)}
					key={x.id}
					className="flex flex-col items-center">
					<i className={`icon-stage-${x.step} 
					${x.id === id ? 'text-Accent/Yellow' : 'text-transparent'}
					 text-Regular(24_30) mb-[0.4rem]`}> </i>
					<div

						className={`
                        ${x.id === id ? 'bg-Accent/Yellow pointer-events-none' : 'bg-Dominant/Dop'}
                        hover:cursor-pointer hover:bg-white hover:border-Accent/Yellow p-[0.5rem] text-center flex-auto w-[12.6rem] h-[4.4rem]  border border-Content/Border flex items-center justify-center
                    `}>

						<div className="text-Regular(14_16)">{x.title}</div>
					</div>
				</div>

			))}
		</div>

	)

}

export default observer(Stages);