import CellPickerHOC from "../cellPickerHOC/cellPickerHOC-mobile";
import usersStore from '../../store/users'
import stageStore from '../../store/stage'


const Cell = ({ current, currentData, small }) => {
	return current ?
		<div className={`
			${small ? 'px-[1.2rem] py-[0.2rem] text-Regular(14_16)' : 'px-[1.2rem] py-[0.9rem]'}
			 h-[100%]`
		}>{current?.username || currentData.username}</div> :
		<div className={`
			${small ? 'px-[1.2rem] py-[0.2rem]' : 'px-[1.2rem] py-[0.9rem]'}
		text-Accent/Red font-medium  h-[100%]`}>Выбрать</div>
}

const renderItem = (onClickHandler) => (item) => {
	return (
		<span
			onClick={(ev) => { ev.stopPropagation(); onClickHandler(item) }}
			key={item.id} className="block text-Regular(16_18) py-[8px]">{item.username}
		</span>
	)
};

const UserCellPicker = CellPickerHOC(renderItem, Cell, 'Выбрать исполнителя')


function PositionUser({ onSetUserHandler, ...props }) {


	if (!props.data) {
		const role = stageStore.stages.find(x => x.id === props.stageId)?.m_role;
		const users = usersStore.users.filter(x => x.m_roles.find(y => y.id === role?.id));
		props.data = users
	}


	if (props.service) {
		return null
	}

	return (
		<UserCellPicker
			{...props}
		></UserCellPicker>
	);
}

export default PositionUser;