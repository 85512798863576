import Authorization from "../components/authorization/authorization-mobile";

function Auth() {
	return (
		<div className="bg-[#ECDB88] justify-center flex items-center flex-col relative h-[100vh] p-[30px]">
			<Authorization></Authorization>
		</div>
	);
}

export default Auth;