
import React from 'react';
import './App.css';
import { AuthContext } from './context/auth.context';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import { useAuth } from './hooks/auth.hook';
import Main from './routes/main';
import Auth from './routes/auth';
import Layout from './components/layout/layout';
import { AxiosProvider } from './context/request.context'
import useCssVariables from './hooks/cssVariables.hook'
import "./fontello/css/fontello.css"
import Desktop from './templates/desktop';
import Mobile from './templates/mobile';
import { useDevice } from './hooks/device.hook'





function App() {


	const { authState, login, logout } = useAuth();

	const isMobile = useDevice();


	useCssVariables()



	return (
		<AuthContext.Provider value={{ authState, login, logout }}>
			<AxiosProvider>
				{!isMobile ?
					<Desktop  authState={authState}></Desktop> :
					<Mobile  authState={authState}></Mobile>
				}

			</AxiosProvider>
		</AuthContext.Provider >
	);
}

export default App;
