import Container from "../container/container";
import UsersPanel from "../usersPanel/usersPanel-mobile";
import Button from "../ui/button/button";
import useOrder from "../../hooks/order.hook";


function Header() {

	const { parse } = useOrder()


	return (

		<div className=" relative z-[101]">
			<Container>
				<div className="py-[26px] flex items-center justify-between">
					<span className="flex gap-[24px] items-center">
						<div className="w-[115px] h-[35px] flex justify-center items-center">
							<img className="max-w-[100%] max-h-[100%]" src="/Logo.png" alt="" />
						</div>
					</span>
					<UsersPanel></UsersPanel>
				</div>

			</Container>
		</div>


	);
}

export default Header;