import { useState, useEffect } from "react";

export const useDevice = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateIsMobile = () => {
      const mediaQueryList = window.matchMedia("screen and (max-width: 1024px)");
      setIsMobile(mediaQueryList.matches);
    };

    updateIsMobile(); // Check initial state

    const mediaQueryList = window.matchMedia("screen and (max-width: 1024px)");

    const handleChange = ({ matches }) => {
      setIsMobile(matches);
    };

    mediaQueryList.addEventListener("change", handleChange);

    // Cleanup: remove event listener on component unmount
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, []);

  return isMobile;
};
