import { useCallback, useContext } from "react";
import { AxiosContext } from "../context/request.context";
const qs = require("qs");

function useOrder() {
  const { authRequest } = useContext(AxiosContext);

  const parse = async () => {
    return await authRequest({
      url: `orders/parser`,
    });
  };

  const getOrder = useCallback(
    async ({ id, positionOptions }) => {
      const query = qs.stringify(
        {
          populate: {
            localComment: true,
            order_status: true,
            userinfo: true,
            deliveryinfo: true,
            layout: true,
            positions: {
              populate: {
                ...positionOptions,
              },
            },
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );

      return await authRequest({
        url: `orders/${id}?` + query,
      });
    },
    [authRequest]
  );

  const setComment = useCallback(
    async ({ commentId, orderId, comment }) => {
      return await authRequest({
        method: "POST",
        url: `comment`,
        data: {
          comment,
          order: orderId,
        },
      });

      // return await authRequest({
      // 	method:'PUT',
      // 	url: `comments/${id}?`,
      // 	data: {
      // 		localComment: comment
      // 	}
      // })
    },
    [authRequest]
  );

  const setReadyDate = useCallback(
    async (data) => {
      return await authRequest({
        method: "PUT",
        url: `orders/setReadyDate`,
        data,
      });
    },
    [authRequest]
  );

  return {
    getOrder,
    setComment,
    parse,
    setReadyDate,
  };
}

export default useOrder;
