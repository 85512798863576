import { useCallback, useContext } from "react";
import { AxiosContext } from '../context/request.context';
import position from "../components/position/position";
const qs = require('qs');

const COUNT_OF_DAYS_OF_CLOSED_POSITIONS = 30


function useDashbord() {

	const { authRequest } = useContext(AxiosContext);


	const getDashbord = useCallback(async ({ stage, page, filter, sort, pageSize, signal }) => {
		if (!sort.path) {
			sort = sort._default
		}

		let options = {
			filters: {
				...filter,
				isCurrentStage: true
			}
		}

		if(options.filters.user){
			const userIds = options.filters.user.id['$in'];
			delete options.filters.user;
			options.filters = {
				...options.filters,
				$or: [
					{
						user: 
						{
							id: {
								$in: userIds
							}
						}
					},
					{
						position: {
							drawings: {
								user: {
									id: {
										$in: userIds
									}
								}
							}
						}
					}
				]
			}
		}


		if (stage) {
			options.filters.stage = {
				id: stage
			}
		}

		if (!stage) {
			const current = { ...options.filters };
			const closedPositionsFilterDate = new Date();
			closedPositionsFilterDate.setDate(closedPositionsFilterDate.getDate() - COUNT_OF_DAYS_OF_CLOSED_POSITIONS);
			options.filters = {
				$or: [
					{
						$and: [
							{
								...current
							},
							{
								updatedAt: {
									$gte: closedPositionsFilterDate.toISOString(),
								},
								stage: {
									endStage: true
								}
							},
						]
					},
					{
						$and: [
							{
								...current
							},
							{
								stage: {
									endStage: false
								}
							}
						]
					}
				]
			}
		}


		const query = qs.stringify({
			populate: {
				stage: true,
				status: true,
				user: true,
				comments: {
					sort: ['createdAt:desc']
				},
				position: {
					populate: {
						drawings: {
							populate: {
								status: true,
								user:true
							}
						},
						order: {
							populate: {
								userinfo: true,
								localComment: true
							}
						}
					},
				},
			},
			pagination: {
				page,
				pageSize
			},
			...options,
			sort
		}, {
			encodeValuesOnly: true, // prettify URL
		});

		return await authRequest({
			url: 'c-position-stages?' + query,
			signal
		})
	}, [authRequest])

	const getPrintLoading = useCallback(async (date) => {

		return await authRequest({
			url: `positionss/getPrintLoading?date=${date}`,
			method: 'GET'
		})
	}, [authRequest])

	const getNotifications = useCallback(async () => {


		return await authRequest({
			url: `notifications`,
			method: 'GET'
		})


	}, [authRequest])

	const getDocsLink = useCallback(async () => {

		const query = qs.stringify({
			fields: ['link']
		}, {
			encodeValuesOnly: true, 
		});

		return await authRequest({
			url: `doc-link?` + query,
			method: 'GET'
		})

	}, [authRequest])

	return {
		getDashbord,
		getPrintLoading,
		getDocsLink,
		getNotifications
	}


}

export default useDashbord;