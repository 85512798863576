import DatePicker from "react-datepicker";
import usersStore from "../../store/users";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useState } from "react";
import useOrder from "../../hooks/order.hook";
import usePosition from "../../hooks/position.hook";


function ReadyTimePicker({ orderId, positionId, callback, small=false, tooltip='' }) {
  const { setReadyDate:setOrderReadyDate } = useOrder();
  const { setReadyDate:setPositionReadyDate } = usePosition();
  const [startDate, setStartDate] = useState(new Date());

  if (!usersStore.isGodMod()) return null;

  const onDateChage = async (value) => {
	console.log(value)
	 value = new Date(value);
	 value.setMilliseconds(0);
    setStartDate(value)
    if(orderId){
        await setOrderReadyDate({
            date: value,
            orderId,
          });
    }
    if(positionId){
        await setPositionReadyDate({
            date: value,
            positionId,
          });
    }

    callback && callback();
  };

  return (
    <div className="text-Regular(14_16)"
	 data-tooltip-id={"comment-info"}
	 data-tooltip-content={tooltip}
	 data-tooltip-place="top"
	 >
    <DatePicker
    portalId="ready-picker-portal"
    selected={startDate}
    popperPlacement={'bottom-end'}
    minDate={new Date()}
    locale="ru"
    dateFormat="MMMM d, yyyy h:mm"
    timeCaption="Время"
    showTimeSelect
    onChange={onDateChage}
    customInput={
      <div className="ease-in-out flex-none duration-300 bg-[#fff] w-[5rem]flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60">
        <i
          className={`${small ? 'text-Regular(14_16)' : 'text-Regular(24_30)'} icon-i-calendar  hover:cursor-pointer  text-Accent/Blue`}
        ></i>
      </div>
    }
  ></DatePicker>
    </div>


  );
}
export default ReadyTimePicker;
