import { makeAutoObservable } from "mobx";
import { defaultTableSettings } from "../hooks/settings.hook";

class Dashbord {
  selectedPositionsStages = [];
  selectedPositionsOrderId = null
  // selectedPositions = []
  settings = {};
  pageSize = 25;

  prevSort = {};
  constructor() {
    makeAutoObservable(this);
  }

  addPosition({ id, position_id, drawings = null, status, blocked, title, code, selectedItemsOrderId = null }) {
    const isExist = this.selectedPositionsStages.findIndex(
      (x) =>
        (x.id === id && id) || (x.position_id === position_id && position_id)
    );
    if (isExist > -1) {
      this.selectedPositionsStages.splice(isExist, 1);
    } else {
      this.selectedPositionsStages.push({
        id,
        position_id,
        drawings,
        status,
        title,
        code,
        blocked
      });
    }
    if(selectedItemsOrderId) {
      this.selectedPositionsOrderId = selectedItemsOrderId
    }
  }

  addPositions(items, selectedItemsOrderId = null) {
    this.selectedPositionsStages = items;
    if(selectedItemsOrderId){
      this.selectedPositionsOrderId = selectedItemsOrderId;
    }
  }

  setSort({ path, name, _default, custom = false, correction = "desc" }) {
    if (_default) {
      return (this.settings.sort = {
        path: null,
        name,
        _default,
        custom,
      });
    }
    this.settings.sort = {
      ...this.sort,
      path,
      name,
      correction,
      custom,
    };
  }

  resetSort() {
    this.sort = {};
  }

  setSettings(settings) {
    console.log(settings)
    this.settings = settings;
  }

  changeColumnWith(key, width) {
    this.settings.table[key].width = width;
  }

  changePageSize(size) {
    this.pageSize = size;
  }

  updateInitialColumnWidth(key) {
    if (this.settings.table[key]) {
      this.settings.table[key].initialWidth = this.settings.table[key].width;
    }
  }

  getSelected() {
    return this.selectedPositionsStages;
  }

  clearSelected() {
    this.selectedPositionsStages = [];
    this.selectedPositionsOrderId = null;
  }

  getIsPositionsSelected(id) {
    return (
      this.selectedPositionsStages.findIndex(
        (x) => x.id === id || x.position_id === id
      ) > -1
    );
  }
}

const dashbord = new Dashbord();

export default dashbord;