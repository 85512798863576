import { useEffect,  useState, forwardRef } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru'
import { toISOStringWithTimezone } from '../../../helpers/common'
import './style.css';
registerLocale('ru', ru)




function Datepicker({ label, labelSmall = false, initial, onChange, showTime = false }) {


	const [startDate, setStartDate] = useState(initial && new Date(initial));

	useEffect(() => {
		setStartDate(initial && new Date(initial));
	}, [initial])

	


	const onChangeHandler = (value) => {
		setStartDate(value);
		if(!showTime){
			const [dateFormated] = toISOStringWithTimezone(value).split('T');
			value = dateFormated
		}else{
			// value = new Date(value).setSeconds(0,0)
			value = new Date(value).setMilliseconds(0)

			value = new Date(value).toISOString();
		}
		onChange({
			value
		})
	}



	const DatePickerContainer = forwardRef(({ value, onClick }, ref) => (
		<input
		readOnly={true}
		className="
		border border-Content/Border
		px-[1.2rem]
		py-[1.7rem]
		block
		w-[100%]
		text-Regular(16_18)
		placeholder:text-Regular(16_18)
		placeholder:text-Content/Middle
		focus:border-Accent/Blue
		focus:outline-0" onClick={onClick} ref={ref} value={value}>
		</input>
	));


	return (
		<div className="flex flex-col items-start">
			{labelSmall ?
				<span className='block text-Regular(12_14) text-Content/Light mb-[0.6rem]'>{label}</span>
				:
				<span className="text-Content/Middle text-Regular(14_16) mb-[0.6rem]">{label}</span>
			}
			<div className="flex w-[100%] relative text-Regular(18_24)">
				<DatePicker
					selected={startDate}
					locale="ru"
					timeCaption="Время"
					showTimeSelect={showTime}
					dateFormat={showTime ? 'dd.mm.yyyy в h:mm' :'dd.mm.yyyy'}
					onChange={(date) => onChangeHandler(date)}
					customInput={<DatePickerContainer />}
				/>
			</div>
		</div>
	);
}

export default Datepicker;