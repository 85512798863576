
import Button from '../../ui/button/button';
import ButtonSimple from '../../ui/buttonSimple/buttonSimple';
import ButtonBorder from '../../ui/buttonBorder/buttonBorder';
import Checkbox from '../../ui/checkbox/checkbox-mobile';
import DefaultModal from '../defaultModal/defaultModal-mobile';




function SettingsModal({ onSubmit, onCancel, selectAll, newSettings, onChange }) {

	return (
		<DefaultModal>

			<div
				className=" z-30  bg-white ">

				<div className=" grid grid-cols-2 gap-[26px]">
					{Object.entries(newSettings || {}).filter(([_, item]) => item.configurable && !item.constant).map(([key, item]) => {
						if (key == 'stage') return null;
						return (
							<div key={key} >
								{item.available}
								<Checkbox
									onChange={() => onChange(key)}
									label={item.title}
									active={item.available}>
								</Checkbox>
							</div>
						)
					}
					)}
				</div>
				<div className=" flex flex-col-reverse justify-between items-center">
					{/* <span>По умолчанию</span> */}
					<div className="flex flex-row-reverse gap-[1.2rem]">
						<Button
							onPress={onSubmit}
							name="Применить"
						>
						</Button>
						<ButtonSimple
							onPress={onCancel}
							name="Отменить"
						></ButtonSimple>
					</div>
					<div className="flex justify-between gap-[1.8rem] w-[100%] py-[18px] my-[26px] border border-Content/Border border-l-0 border-r-0">
						<ButtonBorder
							onPress={() => selectAll(true)}
							name="Выбрать всё">
						</ButtonBorder>
						<ButtonBorder
							onPress={() => selectAll(false)}
							name="Отменить всё"
						></ButtonBorder>
					</div>
				</div>
			</div>
		</DefaultModal>

	)
}

export default SettingsModal;