import { useEffect, useRef, useState } from "react";
import DropdownHOC from "../../dropdownHOC/dropdownHOC";
import stageStore from "../../../store/stage";
import DefaultModal from "../defaultModal/defaultModal";
import Button from "../../ui/button/button";
import usePosition from "../../../hooks/position.hook";
import usersStore from "../../../store/users";
import Checkbox from "../../ui/checkbox/checkbox-mobile";
import Loader from "../../loader/loader";
import useProblems from "../../../hooks/problems.hook";

function MoveToStageModal({ moveToStageModal, getData, setMoveToStageModal }) {
  const { positionId, callback } = moveToStageModal;

  const [loader, setLoader] = useState(false);
  const textareaRef = useRef(null);
  const [problems, setProblems] = useState([]);
  const { moveToStage } = usePosition();
  const {getProblems}= useProblems();

  useEffect(() => {
    (async()=>{
      const problems = await getProblems();
      setProblems(problems.data);
    })();
  },[])
  
  const {
    currentUser: {
      role: [{ name }],
      username,
    },
  } = usersStore;
  let { currentStage, stages } = stageStore;

  stages = stages.filter((x) => x.step < currentStage.step || x.additionalWork);

  const onProblemChange = (id) => {
    setProblems(prev => {
      const item = prev.find(x=>x.id === id);
      item.active = !item.active;
      return [...prev]
    })
  }


  const [stage, setStage] = useState(null);

  const onSubmit = async () => {
    setLoader(true);
    await moveToStage({
      positionId,
      stageId: stage.id,
      problems: problems.filter(x => x.active).map(x =>x.id ),
      comment: {
        nameOwner: username,
        roleOwner: name,
        comment: textareaRef.current.value,
      },
    });
    setMoveToStageModal({
      isOpen: false,
    });
    callback && callback();
  };

  const dropdownItems = stages.map((x) => ({
    onEvent: () => setStage(x),
    title: x.title,
  }));

  return (
    <div className="w-[90vw] max-w-[76rem] min-h-[10rem]">
      <DefaultModal>
        {loader ? (
          <Loader></Loader>
        ) : (
          <>
            <div className="flex gap-[1.8rem] items-center mb-[1.6rem]">
              <span className="text-Regular(18_24)">
                Отправить позицию на этап
              </span>
              <div className="min-w-[20rem]">
                <DropdownHOC
                  items={dropdownItems}
                  title={stage?.title || "Выберите этап"}
                ></DropdownHOC>
              </div>
            </div>

            <div className="flex flex-col gap-[2.4rem]">
              <textarea
                placeholder="Введите комментарий"
                ref={textareaRef}
                className="p-[1.2rem] text-Regular(16_20) border w-[100%] border-Content/Border rounded-[4px] min-h-[14rem]"
              />
              {stage && !stage.additionalWork && (
                <div className="flex flex-col gap-[10px]">
                  {problems.map(({title, id, active}) =>  <Checkbox key={id} active={active} label={title} onChange={() => onProblemChange(id)}></Checkbox>)}
                
                </div>
              )}
              <div className="self-end">
                <Button
                  onPress={onSubmit}
                  disabled={!stage}
                  name="Отправить"
                ></Button>
              </div>
            </div>
          </>
        )}
      </DefaultModal>
    </div>
  );
}

export default MoveToStageModal;
