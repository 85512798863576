
import Dashbord from "../components/dashbord/dashbord-mobile";
import Stages from "../components/stages/stages-mobile";

function Main() {

	return (
		<>
			<Stages></Stages>
			<Dashbord ></Dashbord>
		</>

	);
}

export default Main;