const Cell = ({ width = "w-[14rem]", height = "h-[10rem]", ml = "ml-[-1px]", mt = "mt-[-1px]", children }) => {
	return (
		<div className={`
			${width}
			${height}
			${ml}
			${mt}

			  text-Regular(12_14)   border border-Content/Border p-[1.2rem] flex justify-center items-center`}>
			{children}
		</div>
	)
}

export default Cell;