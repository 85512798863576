import { observer } from "mobx-react-lite"
import stageStore from "../../store/stage"
import usersStore from "../../store/users"
import useStages from "../../hooks/stages.hook";
import { useEffect } from "react";
import dashbordStore from "../../store/dashbord";

function Stages() {

	const { stages = [] } = useStages();
	const { id } = stageStore.currentStage || {};
	const { role } = usersStore.currentUser;


	useEffect(() => {
		if (role && stages.length) {
			const stage = stages.find(x => x?.id === role[0].stage?.id);
			stageStore.setCurrentStage(stage)
			stageStore.setStages(stages)
		}
	}, [role, stages])


	const onStageChange = (stage) => {
		stageStore.setCurrentStage(stage)
		dashbordStore.clearSelected()
	}

	if(!stages.length) return null

	return (
		<div className="pl-[18px] flex gap-[0.8rem] py-[1.8rem] overflow-auto bg-Dominant/Dop">
			<div
				onClick={() => onStageChange(null)}
				className={`
                        ${!id ? 'bg-Accent/Yellow pointer-events-none' : 'bg-Dominant/Dop'}
                         p-[0.5rem] text-center  w-[120px] flex-[0_0_auto]   border border-Content/Border flex items-center justify-center
                    `}>
				<div className="text-Regular(14_16)">Все заказы</div>
			</div>
			{stages.map(x => (
				<div
					onClick={() => onStageChange(x)}
					key={x.id}
					className={`
                        ${x.id === id ? 'bg-Accent/Yellow pointer-events-none' : 'bg-Dominant/Dop'}
                        p-[0.5rem] text-center  w-[120px]    border border-Content/Border flex flex-[0_0_auto] items-center justify-center
                    `}>
					<div className="text-Regular(14_16)">{x.title}</div>
				</div>
			))}
		</div>

	)

}

export default observer(Stages);