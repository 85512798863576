import React, {useEffect, useState} from 'react';
import OutsideAlerter from "../outsideAlerter/outsideAlerter";
import dashbordStore from "../../store/dashbord";
import {observer} from "mobx-react-lite";
import usePosition from "../../hooks/position.hook";
import {emmiter} from "../../helpers/Emmiter";
import {autorun} from 'mobx'
import Modal from "../modal/modal-mobile";
import stageStore from "../../store/stage";
import { MdOutlineDoneOutline } from "react-icons/md";

export const StageStatuses = observer((
    {
        stageStatuses = [],
        step = null,
        isMobileView = false,
        isMobileModalOpen,
        setIsMobileModalOpen
    }
) => {
    const {groupSetStatus, groupSetPrintStatus, moveGroupToAdditionalWorkStage} = usePosition()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState({})
    const [highestStrictStep, setHighestStrictStep] = useState(0)
    const [isAlertModal, setIsAlertModal] = useState(false)
    const [multiStampPositions, setMultiStampPositions] = useState([])
    const [blockedSelected, setBlockedSelected] = useState(false)
    const {selectedPositionsStages} = dashbordStore;


    useEffect(() => {
        autorun(()=>{
            let highestStrictStepNum = 0
            setMultiStampPositions([])
            selectedPositionsStages.forEach(el => {
                if(!el.drawings.length || step !== 5) {
                    if (el.status?.strictStepNumber > highestStrictStepNum) {
                        highestStrictStepNum = el.status?.strictStepNumber
                    }
                } else if (el.drawings.length && step === 5) {
                    el.drawings.forEach(drawing => {
                        if (drawing.status?.strictStepNumber > highestStrictStepNum) {
                            highestStrictStepNum = drawing.status?.strictStepNumber
                        }
                    })
                }
                if (el.drawings?.length > 1 && step === 5) {
                    setMultiStampPositions([el, ...multiStampPositions])
                }
                !blockedSelected && !!el.blocked && setBlockedSelected(true)
            })
            setHighestStrictStep(highestStrictStepNum)
        })
    }, []);



    const onSetStatusChange = async () => {
        if (selectedStatus.additionalWorkTrigger) {
            const { stages } = stageStore;
            const { id } = stages.find(x => x.additionalWork);
            emmiter.emmit('setCommentModal', {
                positionsIDs: selectedPositionsStages.map(el => el.position_id),
                isOpen: true,
                isFullMod: false,
                stageId: id,
                title: 'Обратите внимание! Вы отправляете на "Доработку" выбранные позиции, со следующим комментарием:',
                callback: async () => {
                    emmiter.emmit('setGroupStatus', selectedStatus)
                    emmiter.emmit('setCommentModal', false)
                    await moveGroupToAdditionalWorkStage(selectedPositionsStages.map(el => el.position_id));
                    setSelectedStatus({})
                    setBlockedSelected(false)
                    dashbordStore.clearSelected();
                },
                buttonName: 'Отправить на доработку'
            })
        } else if(step === 5) {
            emmiter.emmit('setGroupStatus', selectedStatus)
            await groupSetPrintStatus(selectedStatus?.id, selectedPositionsStages)
        } else {
            emmiter.emmit('setGroupStatus', selectedStatus)
            await groupSetStatus(selectedStatus?.id, selectedPositionsStages)
        }
        if(!selectedStatus.additionalWorkTrigger) {
            setSelectedStatus({})
            setBlockedSelected(false)
            dashbordStore.clearSelected()
        }
        isMobileView && setIsMobileModalOpen(false)
    }


    const renderStatusesList = stageStatuses.map((el) => {
        const isDisabled = !(el.strictStepNumber >= highestStrictStep || !el.isStrict)
        const onStatusSelect = async () => {
            if (isDisabled || multiStampPositions.length || blockedSelected) {
                setIsAlertModal(true)
                isMobileView && setIsMobileModalOpen(false)
            } else {
                setSelectedStatus({title: el.title, id: el.id, ...el})
            }
        }

        return (
            <div className={`relative flex items-center gap-[10px]`}>
                <div onClick={onStatusSelect} key={el.id}
                     className={`
                text-Regular(16_20) p-[12px] 
                ${isDisabled ? 'opacity-[0.5]' : ''}
                hover:opacity-60 ease-in-out duration-300`}>
                    {el.title}
                </div>
                {isMobileView && selectedStatus.id === el.id && <span>
                    <MdOutlineDoneOutline
                        style={{color: "#20c52b"}}
                        size={20}
                    />
                </span>}
            </div>

        )
    })

    const renderedMultiPrintItems = multiStampPositions.length &&
        multiStampPositions.map(el => {

            return (
                <div className={`grid grid-cols-[65%_1fr] text-Regular(14_16)`}>
                    <div className={'grid grid-rows-2 px-[10px]'}>
                        <span>Наименование:</span>
                        <span>{el.title}</span>
                    </div>
                    <div className={'grid grid-rows-2 px-[10px]'}>
                        <span>Артикул: </span>
                        <span>{el.code}</span>
                    </div>
                </div>
            )
        })

    return (
        <>
            {!isMobileView
                ? <div className={`flex gap-[26px] px-[20px]`}>
                    <OutsideAlerter onEvent={() => setIsMenuOpen(false)}>
                        <div onClick={() => setIsMenuOpen(!isMenuOpen)}
                             className={`relative min-w-[215px] flex items-center justify-between gap-[4px] py-[9px] px-[14px] 
            border border-Content/Border hover:cursor-pointer ease-in-out 
            duration-300 hover:border-Accent/Blue`}>
                            <div className={'text-Regular(18_24)'}>
                                {selectedStatus.id ? selectedStatus.title : 'Отправить в статус'}
                            </div>
                            <i className={`icon-arrow  text-Content/Middle text-Regular(18_24)`}></i>
                            {isMenuOpen &&
                                <div
                                    className={'absolute border border-Content/Border bg-[#FFFFFF] top-[-2px] left-[0] right-[0] translate-y-[-100%] mx-[-1px] max-h-[33.5rem] overflow-auto'}>
                                    {renderStatusesList}
                                </div>
                            }
                        </div>
                    </OutsideAlerter>
                    <div onClick={onSetStatusChange}
                         className={`${selectedStatus.id ? 'bg-Accent/Yellow' : 'bg-Dominant/Dop pointer-events-none'} border border-Content/Border flex items-center rounded-[8px] py-[9px] px-[14px] text-Regular(16_18) hover:cursor-pointer ease-in-out 
                duration-300 hover:border-Dominant/Dop`}>
                        Сменить статус
                    </div>
                    <span
                        onClick={() => {
                            dashbordStore.clearSelected()
                        }}
                        className='w-[4rem] h-[4rem] flex items-center justify-center hover:cursor-pointer hover:rotate-180 ease-in-out duration-300 hover:text-Accent/Blue'>
					<i className='icon-close text-Regular(38_42) flex items-center justify-center'></i>
				</span>
                </div>
                : <Modal title={'Изменить статус'}
                           closeModal={() => setIsMobileModalOpen(false)}
                           isOpen={isMobileModalOpen}>
                        <div className={`min-w-[350px] px-[10px]`}>
                            {renderStatusesList}
                        </div>
                        <div className={`p-[10px] flex items-center justify-center gap-[20px]`}>
                            <div onClick={onSetStatusChange}
                                 className={`${selectedStatus.id ? 'bg-Accent/Yellow' : 'bg-Dominant/Dop pointer-events-none'} border border-Content/Border flex items-center rounded-[8px] py-[9px] px-[14px] text-Regular(16_18) hover:cursor-pointer ease-in-out 
                                duration-300 hover:border-Dominant/Dop`}>
                                Сменить статус
                            </div>
                            <div onClick={() => {setIsMobileModalOpen(false); setSelectedStatus({})}}
                                 className={`bg-Dominant/Dop border border-Content/Border flex items-center rounded-[8px] py-[9px] px-[14px] text-Regular(16_18) hover:cursor-pointer ease-in-out 
                                duration-300 hover:border-Dominant/Dop`}>
                                Отменить
                            </div>
                        </div>
                    </Modal>
            }
            <Modal title={'Внимание!'} closeModal={() => setIsAlertModal(false)} isOpen={!!isAlertModal}>
                <div
                    className={`w-[90vw] max-w-[60rem] min-h-[10rem] py-[3rem] px-[8rem] flex flex-col items-center gap-[2rem]`}>
                    {multiStampPositions.length
                        ? <>
                            <div className={`text-Regular(18_24) text-center`}>
                            Извините, вы не можете массово изменить статус, так как в
                                вашей выборке есть следующие позиции с несколькими
                                методами печати:
                            </div>
                            {renderedMultiPrintItems}
                        </>
                        : <div className={`text-Regular(18_24) text-center`}>
                            {blockedSelected
                                ? `Вы не можете изменить статус, так как в вашей выборке есть позиции на этапе "Доработка" `
                                : ` Извините, вы не можете вернуть позиции на более ранний статус
                            выполнения. Пожалуйста, снимите чек-боксы с неподходящих
                            позиций `}

                        </div>}
                    <div onClick={() => setIsAlertModal(false)}
                         className="bg-Accent/Yellow inline-block py-[1.1rem] group px-[1.8rem]  hover:cursor-pointer  font-medium rounded-[8px]  text-Content/Dark text-Regular(16_20)">
                        <div
                            className="relative group-hover:text-Content/Middle text-Content/Dark ease-in-out duration-300 items-center ">
                            <div className="opacity-100 flex gap-[0.8rem]">
                                <span>Понятно</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
});

