import React from "react";
import stageStore from "../../store/stage";


const Wrapper = ({ td = true, style, children, className, ...rest }) => {
  if (td) {
    return (
      <td {...rest} style={style} className={className}>
        {children}
      </td>
    );
  } else {
    return (
      <div {...rest} style={style} className={className}>
        {children}
      </div>
    );
  }
};

function Cell({
  td = true,
  flex = true,
  padding = true,
  height = "h-[6rem]",
  available = true,
  disabled = false,
  children,
  className,
  checkbox,
  width,
  ...rest
}) {
  const { currentStage } = stageStore;



  if (!available) return null;

  if (disabled)
    return (
      <Wrapper
        className="bg-Content/Light border border-Content/Border"
        td={td}
        {...rest}
      ></Wrapper>
    );

  return (
    <Wrapper
      {...rest}
      td={td}
      style={{
        width: (width || 5) + "rem",
      }}
      className={`
			
			${checkbox ? "flex-0 w-[4.8rem]" : "flex-0 overflow-hidden"}
			${padding ? "px-[1.2rem] py-[0.9rem]" : "px-[0px] py-[0px]"}
         ${currentStage.stampStage && currentStage.id ? "h-[9rem]" : height}
			${flex ? "flex   items-center" : ""}
			${className}
			`}
    >
      {children}
    </Wrapper>
  );
}
export default React.memo(Cell);
