import DropdownHOC from "../dropdownHOC/dropdownHOC";
import dashbordStore from '../../store/dashbord';


const pageAroundVisible = 10;

function Pagination({ pageCount, page, total, onPageChange, pageSize }) {


	const pages = Array.from(Array(pageCount).keys()).map(x => ({ index: x, visible: false }));

	const prepare = () => {
		for (let i = 0; i < pages.length; i++) {
			if (pages[i].index + 2 < page + pageAroundVisible / 2 && pages[i].index + pageAroundVisible / 2 > page) {
				pages[i].visible = true;
			}
			if (pages[i].index == 0) {
				pages[i].visible = true;
			}
			if (pages[i].index == pages.length - 1) {
				pages[i].visible = true;
			}
		}

		for (let i = page; i < pages.length; i++) {
			if (pages[i]?.visible === false) {
				pages[i - 1].spread = true;
				pages[i - 1].spreadPage = Math.ceil((pages.length + page) / 2);
				break
			}
		}

		for (let i = page; i > 0; i--) {
			if (pages[i]?.visible === false) {
				pages[i + 1].spread = true;
				pages[i + 1].spreadPage = Math.ceil(page / 2);
				break
			}
		}
	}

	prepare()

	const onPageChangeHandler = (index) => {
		onPageChange(index + 1)
	}

	const onPageSizeChange = ({ size }) => {
		dashbordStore.changePageSize(size)
	}


	return (
		<div className="flex items-center justify-between h-[100%]">
			<div className="flex gap-[1.2rem]  ">
				{pages.filter(x => x.visible).map(({ index, spread, spreadPage }) =>
					<div
						key={index}
						onClick={() => onPageChangeHandler(spreadPage || index)}
						className={`
                        ${index + 1 === page ? 'bg-[#D5E6FF] text-Accent/Blue border-[#D5E6FF] pointer-events-none' : 'border-Content/Border'}
                        hover:cursor-pointer hover:border-Accent/Blue ease-in-out duration-300 border  w-[3.8rem] h-[3.8rem] flex justify-center items-center
                    `}>
						{spread ?
							<span className="text-Content/Middle text-Regular(18_24)">. . .</span>
							:
							<span className="text-Content/Middle text-Regular(14_16)">
								{index + 1}
							</span>
						}

					</div>)}
			</div>
			<div className="flex gap-[1.8rem] ml-[auto] items-center">
				<span >
					<span className="text-Regular(16_18) font-semibold text-Content/Dark">{page}-{pageSize}</span>
					<span className="text-Regular(16_18)  text-Content/Middle"> из </span>
					<span className="text-Regular(16_18) font-semibold text-Content/Dark">{total}</span>
				</span>
				{/* <div className="flex gap-[1.8rem] items-center">
					<span className="text-Regular(16_18) text-Content/Middle">На странице</span>
					<DropdownHOC
						title={pageSize}
						items={
							[
								{
									onEvent: () => onPageSizeChange({
										size: 10
									}),
									title: '10'
								},
								{
									onEvent: () => onPageSizeChange({
										size: 25
									}),
									title: '25'
								},
								{
									onEvent: () => onPageSizeChange({
										size: 50
									}),
									title: '50'
								},
								// {
								// 	onEvent: () => onPageSizeChange({
								// 		size: 100
								// 	}),
								// 	title: '100'
								// }
							]
						}
					></DropdownHOC>
				</div> */}

			</div>

		</div>

	);
}

export default Pagination;